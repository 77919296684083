import React from "react";
import SearchResultPage from "../../../templates/search-results-map-template";

const SearchResultCommercialSalesMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/commercial-property-map/for-sale/" 
                pcategorytype="commercial" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="for sale"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Sales"
            />
        </React.Fragment>
    );
};

export default SearchResultCommercialSalesMap;